import React from "react";

import Typography from "@mui/material/Typography";

import { UI_STAGE } from "../App";

export default function ReleaseDate({ }) {
  return (
    <Typography variant="caption" color="darkgray">
      You are using the{" "}
      <span style={{ textDecoration: "none" }}>August 26th, 2024</span>
      {UI_STAGE !== "prod" && (
        <Typography variant="caption" color="darkgray">
          {" "}[{UI_STAGE}]
        </Typography>
      )}
      {" "}release
    </Typography>
  );
}
